import React, { useEffect, useState } from 'react';
import './App.css';

type MYJSON = {
  description: string,
  week: number,
  data: Object,
};

function App() {

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token') || ''; // 5ccnEYKV
  // console.log(token);
  const [average, setAverage] = useState<any>({});
  const [weekBeforeLast, setWeekBeforeLast] = useState<any>({});
  const [lastWeek, setLastWeek] = useState<any>({});
  const [currentWeek, setCurrentWeek] = useState<any>({});
  const currentWeekUri = 'https://ha9.ru/api/leads/monitor/losses/currentWeek';
  const lastWeekUri = 'https://ha9.ru/api/leads/monitor/losses/lastWeek';
  const weekBeforeLastUri = 'https://ha9.ru/api/leads/monitor/losses/weekBeforeLast';
  const averageUri = 'https://ha9.ru/api/leads/monitor/losses/average';

  async function fetchData(uri: string, cb: Function) {
    try {
      const response1 = await fetch(uri);
      if (response1.status !== 200) throw new Error('cannot fetch data');
      const json1: MYJSON = await response1.json();
      // console.log(json1.data);
      cb(json1.data);
    } catch (e: any) {
      console.log(e.message);
    }
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        await fetchData(`${averageUri}?token=${token}`, setAverage);
        await fetchData(`${weekBeforeLastUri}?token=${token}`, setWeekBeforeLast);
        await fetchData(`${lastWeekUri}?token=${token}`, setLastWeek);
        await fetchData(`${currentWeekUri}?token=${token}`, setCurrentWeek);
      } catch (e:any) {
        console.log(e.message);
      }
    };
    loadData();
  }, [token]);

  const compare = (a: Number | undefined, b: Number | undefined) => {
    if (a === undefined || b === undefined) return 'white';
    if (a > b) return 'red';
    else return 'green';
  }

  const tableContent = Object.keys(average).map((x) => (
    <tr key={x}>
      <td translate="no">{x}</td>
      <td>{average[x]}</td>
      <td className={compare(weekBeforeLast[x], average[x])}>{weekBeforeLast[x] || '-'}</td>
      <td className={compare(lastWeek[x], average[x])}>{lastWeek[x] || '-'}</td>
      <td className={compare(currentWeek[x], average[x])}>{currentWeek[x] || '-'}</td>
    </tr>
  ));
  const header = tableContent.length > 0 ?
    (<tr className='Table-header'>
      <th>Направление</th>
      <th>последние 10 недель</th>
      <th>позапрошлая неделя</th>
      <th>прошлая неделя</th>
      <th>текущая неделя</th>
    </tr>) :
    (<tr><td></td></tr>);

  const caption = tableContent.length > 0 ?
  (<caption>Процент недозвонов по направлениям</caption>) : (<caption></caption>);

  return (
    <div className="App">
      <div className='App-wrapper'>
        <table className='App-table'>
          {caption}
          <tbody>
            {header}
            {tableContent}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default App;
